import styled, {css} from "styled-components";
import {whenTablet} from "../utils/responsive.js";

export const H3 = styled.h3`
  font-weight: bold;
  
  font-size: 2.0rem;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
  
  ${whenTablet(css`
    font-size: 3rem;
  `)}
`;

export const H4 = styled.h4`
  font-size: 1.6rem;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
  
  ${whenTablet(css`
    font-size: 2rem;
  `)}
`;

export const P = styled.p`
  font-size: 1.6rem;
`;