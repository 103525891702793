export const cards = {
  diamond: "diamond",
  gold: "gold",
  captain: "captain",
  monkeyBusiness: "monkeyBusiness",
  peace: "peace",
  pirateShip2: "pirateShip2",
  pirateShip3: "pirateShip3",
  pirateShip4: "pirateShip4",
  seaBattle: "seaBattle",
  skulls1: "skulls1",
  skulls2: "skulls2",
  sorceress: "sorceress",
  treasureChest: "treasureChest"
}

export const deckContents = [
  cards.diamond,
  cards.diamond,
  cards.diamond,
  cards.diamond,
  cards.gold,
  cards.gold,
  cards.gold,
  cards.gold,
  cards.captain,
  cards.captain,
  cards.captain,
  cards.monkeyBusiness,
  cards.monkeyBusiness,
  cards.monkeyBusiness,
  cards.monkeyBusiness,
  cards.peace,
  cards.peace,
  cards.pirateShip2,
  cards.pirateShip2,
  cards.pirateShip2,
  cards.pirateShip3,
  cards.pirateShip3,
  cards.pirateShip3,
  cards.pirateShip4,
  cards.pirateShip4,
  cards.pirateShip4,
  cards.seaBattle,
  cards.seaBattle,
  cards.skulls1,
  cards.skulls1,
  cards.skulls1,
  cards.skulls2,
  cards.skulls2,
  cards.sorceress,
  cards.sorceress,
  cards.sorceress,
  cards.treasureChest,
  cards.treasureChest,
  cards.treasureChest,
];