// responsive util
import {css} from "styled-components";
import theme from "../styled_components/theme.js";

export const whenTablet = (input) => {
  return css`
    @media only screen and (min-width: ${theme.breakpoints.tablet}) {
      ${input}
    }
  `;
}