import styled, {css} from "styled-components";
import {whenTablet} from "../utils/responsive.js";

const FullWidthButton = styled.button`
  display: block;
  margin: 0 auto;
  box-sizing: border-box;
  border: 0;
  font-size: 1.8rem;
  font-weight: bold;
  padding: 15px 5px;
  width: 100%;
  cursor: pointer;

  ${whenTablet(css`
    font-size: 2.2rem;
  `)}
`;

export const DarkFullWidthButton = styled(FullWidthButton)`
  color: white;
  background-color: ${props => props.color || "black"};
`;

export const BigButtonPair = styled.div`
  display: flex;
  flex-wrap: wrap;
  
  & > * {
    min-width: 200px;
    flex: 1;
  }
`;