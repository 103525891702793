import {cards} from "./deck.js";

const cardTexts = {
  [cards.captain]: "Double the points you earn on this buccaneering quest.  If you go to Skull Island, your opponents loose 200 points for each skull you roll.",
  [cards.diamond]: "Your buccaneering quest starts with 1 extra diamond, which gives you points both as a single diamond, and in combination with other diamonds that you roll.",
  [cards.gold]: "Your buccaneering quest starts with 1 extra gold coin, which gives you points both as a single gold coin, and in combination with other gold coins that you roll.",
  [cards.monkeyBusiness]: "Any monkeys or parrots you roll count as one combination.  For example, if you roll 2 parrots and 3 monkeys, they count as 5 of a kind.",
  [cards.peace]: "You must end your turn without any sabres.  If you fail you get -1000 points for each sabre rolled, and no points for any other dice or combinations.  You are allowed to go to Skull Island, but even there you must not roll sabres.  (If you end your turn on Skull Island with sabres your opponents will not get any negative points)",
  [cards.pirateShip2]: "You must roll at least 2 sabers.  If you succeed, you earn 300 points in addition to the other points you've earned (the sabres you’ve rolled still count in combinations).  If you fail, you don’t score points from any dice and 300 points are deducted from your score.  You cannot go to Skull Island.",
  [cards.pirateShip3]: "You must roll at least 3 sabers.  If you succeed, you earn 500 points in addition to the other points you've earned (the sabres you’ve rolled still count in combinations).  If you fail, you don’t score points from any dice and 500 points are deducted from your score.  You cannot go to Skull Island.",
  [cards.pirateShip4]: "You must roll at least 4 sabers.  If you succeed, you earn 1000 points in addition to the other points you've earned (the sabres you’ve rolled still count in combinations).  If you fail, you don’t score points from any dice and 1000 points are deducted from your score.  You cannot go to Skull Island.",
  [cards.seaBattle]: "You are only allowed to re-roll your dice once. You receive double points for diamonds and gold coins.  If you visit Skull Island, you may still only re-roll once.  If your turn ends with 3 skulls, you score according to Skull Island rules.",
  [cards.skulls1]: "Your turn starts with 1 skull.  They are added to any skulls you roll.",
  [cards.skulls2]: "Your turn starts with 2 skulls.  They are added to any skulls you roll.",
  [cards.sorceress]: "Once during your turn, you are allowed to re-roll one of the skulls you have previously rolled.",
  [cards.treasureChest]: "After each roll, place 1 or more dice on this card. You can remove and re-roll them if you want. If you roll your 3rd skull your turn ends and no more dice from that roll may be placed on this card. Only the points you’ve earned from dice placed on this card will be counted."
};

export default cardTexts;