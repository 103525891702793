import headerPhoto from './assets/images/piraten-kapern.png';
import {useState} from "react";
import {shuffle} from 'lodash';
import {deckContents} from "./game_data/deck.js";
import * as cardImages from "./game_data/cardImages.js";
import cardTexts from "./game_data/cardTexts.js";
import rules from "./assets/Piraten-Kapern-rulebook.pdf";
import {H3, H4} from "./styled_components/textElements.js";
import {AppContainer, CardImage, CardText, DrawCardButton, FlexCenterWrap, HeaderImage} from "./App.styles.js";
import {BigButtonPair, DarkFullWidthButton} from "./styled_components/buttons.js";

const randomDeck = () => {
  return shuffle(deckContents);
}

const Header = () => {
  return <div>
    <HeaderImage
      src={headerPhoto}
      alt={"Piraten Kapern"}
    />
  </div>;
}

const Deck = ({deck, draw}) => {
  return <div>
    <DrawCardButton
      onClick={draw}
      noImage={!deck.length}
    >
      <H3>
        {deck.length ? "Draw a Card" : "Shuffle Deck"}
      </H3>
      <H4 style={{marginTop: "2rem"}}>
        ({deck.length} remaining)
      </H4>
    </DrawCardButton>
  </div>;
}

const Card = ({card}) => {
  return cardImages[card]
    ? <CardImage
      src={cardImages[card]}
      alt={card}
    />
    : null;
}

const FlipDeck = ({deck, card, draw}) => {
  return <FlexCenterWrap style={{margin: "3rem 0"}}>
    <Deck deck={deck} draw={draw}/>
    <Card card={card} />
  </FlexCenterWrap>
}

const CardDescription = ({card}) => {
  return card
    ? <CardText>{cardTexts[card]}</CardText>
    : null;
};

const ScoreCard = () => {
  return <div>
    <H4>Score Card</H4>
    <Card card={"score"} />
  </div>;
}

const FooterButtons = ({resetDeck}) => {
  return <BigButtonPair
    style={{marginTop: "2rem"}}
  >
    <DarkFullWidthButton as="a" href={rules} download style={{textDecoration: "none"}}>
      Download Rules
    </DarkFullWidthButton>
    <DarkFullWidthButton color={"crimson"} onClick={resetDeck}>
      Reset Game
    </DarkFullWidthButton>
  </BigButtonPair>
}

const App = () => {
  const [deck, setDeck] = useState(randomDeck());
  const [card, setCard] = useState(null);

  const resetDeck = () => {
    setDeck(randomDeck());
    setCard(null);
  }

  const drawCard = () => {
    if (deck.length >= 1) {
      setCard(deck[0]);
      setDeck(deck.slice(1));
    } else {
      resetDeck()
    }
  }

  return (
    <AppContainer>
      <Header />
      <FlipDeck deck={deck} card={card} draw={drawCard}/>
      <CardDescription card={card} />
      <ScoreCard />
      <FooterButtons resetDeck={resetDeck}/>
    </AppContainer>
  );
}

export default App;
