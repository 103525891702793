import styled, {css} from "styled-components";
import * as cardImages from "./game_data/cardImages.js";
import {whenTablet} from "./utils/responsive.js";
import {P} from "./styled_components/textElements.js";

export const AppContainer = styled.div`
  text-align: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`

export const HeaderImage = styled.img`
  width: 100%;
`;

export const CardImage = styled.img`
  width: 220px;
  height: 330px;

  ${whenTablet(css`
    width: 300px;
    height: 450px;
  `)}
`;

const optionalBackgroundImage = css`
  background-image: ${props => props.noImage
    ? "none"
    : "url(" + cardImages.cardBack + ")"
  };
`;

export const DrawCardButton = styled.button`
  background-color: lightgray;
  ${optionalBackgroundImage};
  background-position: center;
  border: 0;
  border-radius: 1rem;
  width: 200px;
  cursor: pointer;
  text-shadow: 1px 0 5px white;

  ${whenTablet(css`
    background-color: ${props => props.noImage ? "lightgray" : "white"};
    ${optionalBackgroundImage};
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 300px;
    height: 450px;
    border: 0;

    &:active {
      color: crimson;
    }

    &:focus {
      outline: none;
    }
  `)}
`;

export const CardText = styled(P)`
  margin: 2rem 1rem;
  min-height: 15rem;

  ${whenTablet(css`
    font-size: 2.2rem;
    margin: 3rem 1rem;
    min-height: 16rem;
  `)}
`;

export const FlexCenterWrap = styled.div`
  ${whenTablet(css`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    
    & > * {
      flex: 1;
    }
  `)}
`;